var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: { label: "审批单号", prop: "approvalNo" },
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请输入审批单号",
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.queryParam.approvalNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParam, "approvalNo", $$v)
                                  },
                                  expression: "queryParam.approvalNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "审批类型",
                                prop: "approvalKind",
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择审批类型",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.queryParam.approvalKind,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParam,
                                        "approvalKind",
                                        $$v
                                      )
                                    },
                                    expression: "queryParam.approvalKind",
                                  },
                                },
                                _vm._l(
                                  _vm.approvalKindOptions,
                                  function (d, index) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: index,
                                        attrs: { value: d.dictValue },
                                      },
                                      [_vm._v(_vm._s(d.dictLabel))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.advanced
                        ? [
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  { attrs: { label: "申请日期起止" } },
                                  [
                                    _c("a-range-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        valueFormat: "YYYY-MM-DD",
                                        format: "YYYY-MM-DD",
                                        "allow-clear": "",
                                      },
                                      on: { change: _vm.handleApplyDateChange },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "a-col",
                        { attrs: { md: (!_vm.advanced && 8) || 24, sm: 24 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "table-page-search-submitButtons",
                              style:
                                (_vm.advanced && {
                                  float: "right",
                                  overflow: "hidden",
                                }) ||
                                {},
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    id: "tableQueryBtn",
                                  },
                                  on: { click: _vm.handleQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "search" } }),
                                  _vm._v("查询"),
                                ],
                                1
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.resetQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "redo" } }),
                                  _vm._v("重置"),
                                ],
                                1
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.toggleAdvanced },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.advanced ? "收起" : "展开") +
                                      " "
                                  ),
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.advanced ? "up" : "down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [_c("div", { staticClass: "table-operations" })]
          ),
          _c("approval-detail", {
            ref: "approvalDetail",
            on: { ok: _vm.getList },
          }),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: _vm.tableSize,
              rowKey: "id",
              tid: "1",
              columns: _vm.columns,
              "data-source": _vm.list,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "createTime",
                fn: function (text, record) {
                  return _c(
                    "span",
                    { attrs: { title: _vm.parseTime(record.createTime) } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.parseTime(record.createTime)) + " "
                      ),
                    ]
                  )
                },
              },
              {
                key: "completeTime",
                fn: function (text, record) {
                  return _c(
                    "span",
                    { attrs: { title: _vm.parseTime(record.completeTime) } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.parseTime(record.completeTime)) + " "
                      ),
                    ]
                  )
                },
              },
              {
                key: "summary",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.formFields, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticStyle: { "text-align": "left" } },
                        [
                          _c("span", [_vm._v(_vm._s(item.filedText) + ":")]),
                          _c("span", [_vm._v(_vm._s(item.fieldValue))]),
                        ]
                      )
                    }),
                    0
                  )
                },
              },
              {
                key: "approvalStatus",
                fn: function (text, record) {
                  return _c(
                    "span",
                    { attrs: { title: _vm.approvalStatusFormat(record) } },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.approvalStatusFormat(record)) + " "
                      ),
                    ]
                  )
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      [
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$refs.approvalDetail.handleDetail(
                                  record
                                )
                              },
                            },
                          },
                          [
                            _c("a-icon", { attrs: { type: "edit" } }),
                            _vm._v("查看 "),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  )
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }